import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "canyou-help-centre-"
    }}>{`Canyou Help Centre 📝`}</h1>
    <p>{`This is where the support articles for Canyou live.`}</p>
    <p>{`Can't find what you need here? Email us at `}<a parentName="p" {...{
        "href": "mailto:support@canyou.com.au"
      }}>{`support@canyou.com.au`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      